<template>
  <div class="card">
    <div class="card-body">

      <b-tabs content-class="mt-3">

        <b-tab :title="$t('Register Individual')" active>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
         <b-form @submit.stop.prevent="handleSubmit(saveIndividual)">

            <b-row>

              <b-col md="6">


                <b-form-group :label="$t('First Name')" label-for="i-first-name">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('First Name')">
                  <b-form-input v-model.trim="individual.data.firstName" id="i-first-name" :placeholder="$t('First Name')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Last Name')" label-for="i-last-name">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('Last Name')">
                  <b-form-input v-model.trim="individual.data.lastName" id="i-last-name" :placeholder="$t('Last Name')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Personal Id')" label-for="i-personal-id">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('Personal Id')">
                  <b-form-input type="number" v-model.number="individual.data.personalId" id="i-personal-id" :placeholder="$t('Personal Id')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Id Type')" label-for="i-id-type">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('Id Type')">
                  <b-form-select id="i-id-type" v-model="individual.data.idType" :options="idTypes"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label-for="i-email" :label="$t('Email')">
                  <ValidationProvider v-slot="{ errors }" rules="required|email" :name="$t('Email')">
                    <b-form-input v-model="individual.data.email" id="i-email" type="email" :placeholder="$t('Email')"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Username')" label-for="i-username">
                  <ValidationProvider v-slot="{ errors }" rules="required|alpha_num" :name="$t('Username')">
                    <b-form-input v-model="individual.data.username" id="i-username" :placeholder="$t('Username')"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

            </b-row>

            <hr>

            <b-row class="justify-content-between">
              <b-col md="auto">
                <b-button ref="resetBtnIndividual" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                  {{ $t('Reset') }}
                </b-button>
              </b-col>
              <b-col md="auto">

                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" to="/partners" variant="outline-secondary" class="mr-1">
                  {{ $t('Back') }}
                </b-button>
                <b-button :disabled="isLoading || !individualFormValidator" v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" >
                  {{ $t('Save') }}
                </b-button>
              </b-col>
            </b-row>

          </b-form>
      </validation-observer>
        </b-tab>


        <b-tab :title="$t('Register Company')">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(saveCompany)">
          <!-- <b-form @submit.prevent> -->
            <b-row>

              <!-- <b-col md="6">
                <b-form-group :label="$t('Company Name')" label-for="c-company-name">
                  <b-form-input v-model="company.data.companyName" id="c-company-name" @keydown.space="(event) => event.preventDefault()" :placeholder="$t('Company Name')"/>
                </b-form-group>
              </b-col> -->
                <b-col md="6">
                  <ValidationProvider v-slot="{ errors }" :rules="{ regex: /^\w{3,}$/ }" :name="$t('Company Name')">
                      <label>{{ $t('Company Name') }}</label>
                    <b-form-input v-model="company.data.companyName"    :placeholder="$t('Company Name')" class="form-control" type="text" />

                    <!-- <b-form-input v-model="company.data.companyName" id="c-company-name" @keydown.space="(event) => event.preventDefault()" :placeholder="$t('Company Name')"/> -->

                    <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>
              <b-col md="6">
                <b-form-group label-for="c-contact-email" :label="$t('Contact Email')">
                  <ValidationProvider v-slot="{ errors }" rules="required|email" :name="$t('Contact Email')">
                  <div class="form-label-group">
                    <b-form-input v-model="company.data.contactEmail" id="c-contact-email" type="email" :placeholder="$t('Contact Email')"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('First Name')" label-for="c-contact-first-name">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('First Name')">
                  <b-form-input v-model="company.data.contactFirstName" id="c-contact-first-name" :placeholder="$t('First Name')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Last Name')" label-for="c-contact-last-name">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('Last Name')">
                  <b-form-input v-model.trim="company.data.contactLastName" id="c-contact-last-name" :placeholder="$t('Last Name')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Commerce Number')" label-for="c-company-commerce-number">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('Commerce Number')">
                  <b-form-input type="number" v-model.number="company.data.companyCommerceNumber" id="c-company-commerce-number" :placeholder="$t('Commerce Number')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Username')" label-for="c-username">
                  <ValidationProvider v-slot="{ errors }" rules="required|alpha_num" :name="$t('Username')">
                  <!-- <div class="form-label-group"> -->
                    <b-form-input v-model="company.data.username" id="c-username" :placeholder="$t('Username')"/>
                  <!-- </div> -->
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('The mobile number of the company representative')" label-for="phoneNumber">
                  <ValidationProvider v-slot="{ errors }" :rules="{ required: true, mobile: /^0?5\d{8}$/ }" :name="$t('The mobile number of the company representative')">
                  <!-- <div class="form-label-group"> -->
                    <b-form-input v-model="company.data.phoneNumber" id="phoneNumber" :placeholder="$t('The mobile number of the company representative')"/>
                  <!-- </div> -->
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('taxNumber')" label-for="taxId">
                  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('taxNumber')">

                  <b-form-input v-model="company.data.taxId" id="taxId" :placeholder="$t('taxNumber')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('address1')" label-for="line1">
                  <div class="form-label-group">
                    <b-form-input v-model="company.data.partnerAddress.line1" id="line1" :placeholder="$t('optional')"/>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('address2')" label-for="line2">
                  <div class="form-label-group">
                    <b-form-input v-model="company.data.partnerAddress.line2" id="line2" :placeholder="$t('optional')"/>
                  </div>
                </b-form-group>
              </b-col>


              <b-col md="6">
                <b-form-group :label="$t('City')" label-for="city">
                  <div class="form-label-group">
                    <b-form-input v-model="company.data.partnerAddress.city" id="city" :placeholder="$t('City')"/>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Postal code')" label-for="zip">
                  <div class="form-label-group">
                    <b-form-input v-model="company.data.partnerAddress.zip" id="zip" :placeholder="$t('Postal code')"/>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
      </validation-observer>
            <hr>

            <b-row class="justify-content-between">
              <b-col md="auto">
                <b-button ref="resetBtnCompany" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                  {{ $t('Reset') }}
                </b-button>
              </b-col>
              <b-col md="auto">

                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" to="/partners" variant="outline-secondary" class="mr-1">
                  {{ $t('Back') }}
                </b-button>
                <b-button :disabled="isLoading || !companyFormValidator" v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" >
                  {{ $t('Save') }}
                </b-button>
              </b-col>
            </b-row>

          </b-form>
      </validation-observer>
        </b-tab>
      </b-tabs>

    </div>
  </div>
</template>


<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      individual: {
        partnerType: 'individual',
        data: {
          firstName: '',
          lastName: '',
          personalId : '',
          idType : null,
          email: '',
          username: ''
        }
      },

      company: {
        partnerType: 'company',
        data: {
          companyName: '',
          contactEmail: '',
          contactFirstName: '',
          contactLastName: '',
          companyCommerceNumber: '',
          username: '',
          taxId: '',
          phoneNumber: '',
          partnerAddress: {
            line1: '',
            city: '',
            zip: '',
            line2: '',
          },
        },
      },

      selected: null,
      isLoading: false,
      idTypes: [
        {value: null, text: this.$t('Please select an option'), disabled: true },
        {value: 'NationalId', text: this.$t('NationalId') },
        {value: 'Iqama', text: this.$t('Iqama') },
      ],

    }
  },
  computed:{
    individualFormValidator(){
       let status = true;
       Object.keys(this.individual.data).forEach(key => {
         if (!this.individual.data[key] || this.individual.data[key] === '')
          {
            status = false;
          }
      })

      return status;
    },
    companyFormValidator(){
      let status = true;
      Object.keys(this.company.data).forEach(key => {
        if (!this.company.data[key] || this.company.data[key] === '')
        {
          status = false;
        }
      })

      return status;
    }
  },
  watch:{
    company:{
      deep:true,
      handler(val){
        this.company.data.companyName = this.company.data.companyName.replace(/\s/g,'')
      }
    }
  },
  methods:{
    saveIndividual()
    {
      this.isLoading = true;
      this.individual.data.personalId = this.individual.data.personalId.toString()
      useJwt.post('/partner/api/domain/partners/register',{
        partnerInfo:this.individual
      })
          .then(({data}) => {
            this.$refs.resetBtnIndividual.click()
            this.$swal({
              icon: 'success',
              title: this.$t('Success'),
              text: this.$t('Partner has been created successfully'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.$router.push('/partners')

          })
          .catch(err => {
            this.$swal({
              icon: 'error',
              title: this.$t('Oops'),
              text: this.$t('Something went wrong'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .finally(() => this.isLoading = false)
    },
    saveCompany()
    {
      this.isLoading = true;
      this.company.data.companyCommerceNumber = this.company.data.companyCommerceNumber.toString()

      useJwt.post('/partner/api/domain/partners/register',{
        partnerInfo:this.company
      })
          .then(({data}) => {
            this.$refs.resetBtnCompany.click()

            this.$swal({
              icon: 'success',
              title: this.$t('Success'),
              text: this.$t('Company has been created successfully'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.$router.push('/partners')
          })
          .catch(err => {
            this.$swal({
              icon: 'error',
              title: this.$t('Oops'),
              text: this.$t('Something went wrong'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .finally(() => this.isLoading = false)
    }
  }
}
</script>
